import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { FeedIcon } from "@/components/icons/FeedIcon";
import { Layout } from "@/components/Layout";
import { PostListItem } from "@/components/PostListItem";

const PostPage: React.FC<PageProps<Queries.PostListQuery>> = ({ data }) => {
  const title = "Post";
  const description = `Boctozのすべての記事を新しい順に一覧で表示しています。`;

  const seoProps: GatsbySeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      url: `https://boctoz.com/post/`,
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="prose pt-12 pb-8">
        <h1 className="md:text-6xl">Post</h1>
        <Link to="/rss.xml">
          <span className="text-2xl text-gray-500 transition-all hover:text-[#ee802f]">
            <FeedIcon />
          </span>
        </Link>
      </div>
      <ul className="mt-8 list-none border-t-2">
        {data.allContentfulPost.nodes.map((props) => (
          <PostListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

// TODO: limit and pagination
export const query = graphql`
  query PostList {
    allContentfulPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...PostListItemProps
      }
    }
  }
`;

export default PostPage;
